import imageTwo from "../../assets/images/1.png";
// import img from '../../assets/images/'

export const slides = [
  {
    name: "-Ashmit",
    testimonial: `I've been in the cable
        business for years,
        but this book opened
        my eyes to new
        possibilities. The 7
        Strategies are a
        game-changer for
        any CCTV dealer!
        
        `,
    city: "SPYON CCTV",
    image: imageTwo,
    alt: "Imageone",
  },
  {
    name: "- Mr. Bharat",
    testimonial: `This book is a gamechanger! It's packed
    with practical
    strategies that
    helped us boost
    profits and gain
    customer loyalty. A
    must-read for
    anyone in the CCTV
    industry!`,
    city: "VISHWA CCTV, Surat",
    image: imageTwo,
    alt: "ImageTwo",
  },
  {
    name: "- Priyansh",
    testimonial: `Sudhir and Rahul
    Makhija's book is a
    gold mine of insights.
    Their marketing
    strategies are a gamechanger for cable
    dealers.
    Highly recommended!`,
    city: "TechConnect Cables, Indore",
    image: imageTwo,
    alt: "Imagethree",
  },
];
